<template>
  <tr>
    <td :class="itemClass">{{ item.item_calculo }}</td>
    <td
      :class="valueClass"
      class="text-right"
      v-for="(value, key) in item.produtos"
      :key="key"
    >
      <template v-if="isNumber">{{ value | toNumber }}</template>
      <template v-else>{{ value | toCurrencyValue }}</template>
    </td>
    <td :class="valueClass" class="text-right">
      <template v-if="isNumber">{{ item.total | toNumber }}</template>
      <template v-else>{{ item.total | toCurrencyValue }}</template>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {},
    isNumber: { default: false },
    itemClass: { default: "" },
    valueClass: { default: "" },
  },
};
</script>
